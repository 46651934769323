class Gallery {
	/**
	 * Create and initialise objects of this class
	 * @param {object} block
	 */
	constructor(block) {
		this.block = block;
		this.init();
	}

	init() {
		this.swiper = new Swiper(this.block.querySelector('.swiper'), {
			slidesPerView: 1,
			spaceBetween: 16,
			navigation: {
				nextEl: this.block.querySelector('.swiper-button-next'),
				prevEl: this.block.querySelector('.swiper-button-prev'),
			},
			pagination: {
				el: this.block.querySelector('.swiper-pagination'),
				type: "fraction",
			},
			scrollbar: {
				el: this.block.querySelector('.swiper-scrollbar'),
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
					grid: {
						rows: 2,
						fill: 'row',
					},
					spaceBetween: 24,
				},
				992: {
					slidesPerView: 3,
					grid: {
						rows: 2,
						fill: 'row',
					},
				},
			},
		});
	}
}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.block-gallery').forEach( block => new Gallery(block) );
});
